import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "card-title"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "list-left"
};
const _hoisted_4 = {
  class: "list-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_update_time = _resolveComponent("update-time");
  const _component_img_content = _resolveComponent("img-content");
  const _component_section_card = _resolveComponent("section-card");
  const _component_t_nav_news = _resolveComponent("t-nav-news");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    "is-white-bg": true,
    "is-header-padding": true,
    class: "merge-list"
  }, {
    default: _withCtx(() => [_ctx.isSpider ? (_openBlock(), _createElementBlock("h1", _hoisted_1, "出海易文章列表")) : _createCommentVNode("", true), _createVNode(_component_update_time), _createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgContentCardList, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item.title
      }, [_createVNode(_component_section_card, {
        title: item.title
      }, {
        default: _withCtx(() => [_createVNode(_component_img_content, {
          list: item.listData
        }, null, 8, ["list"])]),
        _: 2
      }, 1032, ["title"])]);
    }), 128))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_t_nav_news, {
      "nav-news-cards": _ctx.navCardList
    }, null, 8, ["nav-news-cards"])])])]),
    _: 1
  });
}