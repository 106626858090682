import { defineComponent, computed } from 'vue';
import { useHead } from '@vueuse/head';
import sectionCard from './components/sectionCard.vue';
import imgContent from './components/imgContent.vue';
import TNavNews from '@/views/News/components/NavNews.vue';
import TLayout from '@/components/Layout/Layout.vue';
import UpdateTime from '@/components/UpdateTime/index.vue';
import { DEFAULT_SEO_CONFIG } from '@/base/config/seo';
import { SeoListMutationEnum } from '@/store/seolist';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getSeoList } from '@/base/api/seolist';
import { useStore } from '@/store';
export default defineComponent({
  name: 'MergeList',
  setup() {
    useHead(DEFAULT_SEO_CONFIG);
    const store = useStore();
    const isSpider = computed(() => {
      return store.state.spiderInfo.isSpider;
    });
    const {
      state: seoListState
    } = useStoreRequest({
      space: 'seolist',
      mutationName: SeoListMutationEnum.SET_SEO_LIST_DATA,
      stateKey: 'seoListData'
    }, getSeoList, {
      term: 'all,market,trends'
    });
    const marketData = computed(() => {
      var _seoListState$value;
      return ((_seoListState$value = seoListState.value) === null || _seoListState$value === void 0 || (_seoListState$value = _seoListState$value.data) === null || _seoListState$value === void 0 ? void 0 : _seoListState$value.market) || [];
    });
    const trendsData = computed(() => {
      var _seoListState$value2;
      return ((_seoListState$value2 = seoListState.value) === null || _seoListState$value2 === void 0 || (_seoListState$value2 = _seoListState$value2.data) === null || _seoListState$value2 === void 0 ? void 0 : _seoListState$value2.trends) || [];
    });
    const all = computed(() => {
      var _seoListState$value3;
      return ((_seoListState$value3 = seoListState.value) === null || _seoListState$value3 === void 0 || (_seoListState$value3 = _seoListState$value3.data) === null || _seoListState$value3 === void 0 ? void 0 : _seoListState$value3.all) || [];
    });
    const navCardList = computed(() => ({
      seolist: all.value
    }));
    const imgContentCardList = computed(() => {
      var _marketData$value, _trendsData$value;
      return [{
        title: '跨境资讯',
        listData: (_marketData$value = marketData.value) === null || _marketData$value === void 0 ? void 0 : _marketData$value.slice(0, 10)
      }, {
        title: '热门电商平台文章',
        listData: (_trendsData$value = trendsData.value) === null || _trendsData$value === void 0 ? void 0 : _trendsData$value.slice(0, 10)
      }];
    });
    return {
      navCardList,
      marketData,
      imgContentCardList,
      isSpider
    };
  },
  components: {
    TLayout,
    TNavNews,
    sectionCard,
    imgContent,
    UpdateTime
  }
});